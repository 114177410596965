export const userReducer = (state={}, action) => {
  switch (action.type) {
    case 'set_current_user':
      if (action.user) {
        return action.user;
      }
      return state;
    default:
      return state;
  }
};