import Requests from '../../util/Requests.js';

export function getEvents() {
  return (dispatch) => {
    dispatch({
      type: 'get_events'
    });

    return Requests.fetch({ 
      url: '/events',
      method: 'GET'
    })
    .then(data => dispatch({
      type: 'receive_events',
      data: data
    }));
  };
}

export function sendChatRequest(args) {
  return (dispatch) => {
    dispatch({
      type: 'send_chat_request'
    });

    return Requests.fetch({ 
      url: '/send-chat-request',
      method: 'POST',
      body: args
    });
  };
}

export function acceptChatRequest(args) {
  return (dispatch) => {
    dispatch({
      type: 'accept_chat_request'
    });

    return Requests.fetch({ 
      url: '/accept-chat-request',
      method: 'POST',
      body: args
    });
  };
}

export function getLastMessages() {
  return (dispatch) => {
    dispatch({
      type: 'get_last_messages'
    });

    return Requests.fetch({ 
      url: '/messages',
      method: 'POST',
      body: {}
    })
    .then(data => dispatch({
    	type: 'receive_last_messages',
    	data: data
    }));
  };
}

export function getMessages(call_type, chat_id, count) {
	return (dispatch) => {
		dispatch({
	      type: 'get_messages'
	    });	

		Requests.fetch({ 
	      url: '/messages',
	      method: 'POST',
	      body: { chat_id: chat_id, count: count }
	    })
	    .then(data => dispatch({
	      type: call_type,
        chat_id: chat_id,
	      data: data
	    }));
	};
}

export function setActiveChat(chat) {
	return {
		type: 'set_active_chat',
		chat: chat,
	};
}

export function receiveMessage(msg) {
	return {
		type: 'receive_message',
		data: msg,
	};
}

export function updateLastActivity(msg) {
  return {
    type: 'update_last_activity',
    data: msg,
  };
}
