import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import MainContainer from './modules/main/MainContainer';
import SignIn from './modules/signin/SignIn';
import Chat from './modules/chat/Chat'

const AppRoutes = () => (
    <Routes>
      <Route path="/*" element={ <MainContainer/> }>
        <Route index element={ <SignIn/> } />
        <Route path="signin" element={ <SignIn/> } />
        <Route path="chat" element={<Chat />}></Route>
      </Route>
    </Routes>
);

export default AppRoutes;
