import React, { Component } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';

import 'bootstrap/dist/css/bootstrap.min.css';

import { Provider } from 'react-redux';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { history, store } from './BaseReducer.js';

import App from './App.js';
// import AppRoutes from './Routes.js';
import SocketProvider from './modules/SocketProvider.js';

class ReactUI extends Component {

  render() {
    return (
      <Provider store={ store }>
        <SocketProvider>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </SocketProvider>
      </Provider>
    );
  }
}

const container = document.getElementById('root');
const root = ReactDOM.createRoot(container);
root.render(<ReactUI />);

