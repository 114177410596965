import React, { Component } from 'react'
import './App.css';
import AppRoutes from './Routes.js';

class App extends Component {
  render() {
    return <AppRoutes />;
  }
}
export default App;


