import React from 'react';
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

import { recipients } from '../../util/helpers.js';

function MessageHeader(props) {
  let r = {}
  if (props.activeChat?.members && props.activeChat?.members.length > 0){
    r = recipients(props.activeChat, props.currentUser);
  }

  return (
    <Row className="message-header no-gutters">
      <Col className="profile-circle-col">
        <div className="profile-circle">
          { r.initials }
        </div>
      </Col>
      <Col className="title">
        { r.username }
      </Col>
    </Row>
  );
}

export default MessageHeader;
