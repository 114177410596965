import React, { Component, useState, useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import Requests from '../../util/Requests.js';

import moment from 'moment';

import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

function MainContainer(props) {

  useEffect(() => {
    Requests.fetch({
      url: '/current-user',
      method: 'GET',
    })
    .then(data => {
      if (data) {
        props.setCurrentUser(data);
      }
    });

  }, []);

  return <Outlet />;
}


const mapStateToProps = (state) => {
  return {}
}

const mapDispatchToProps = (dispatch) => {
  return {
    setCurrentUser: (user) => dispatch({ 
      type: 'set_current_user', 
      user: user
    })
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MainContainer);
