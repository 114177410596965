import React, { Component, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';

import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

  import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
  import { faPaperPlane } from '@fortawesome/free-regular-svg-icons'

function MessageInput(props) {

  const navigate = useNavigate();
  const [message, setMessage] = useState('');
  const [formError, setFormError] = useState('');
  const [socket, setSocket] = useState(false);

  useEffect(() => {
    console.log("Running on mount from ", props.socket);
  }, []);

  const sendMessage = () => {
    
    const m = {
      user: props.currentUser,
      chat_id: props.activeChat.chat_id,
      body: message
    };
    console.log("Sending message:", m);
    props.socket.emit('send-message', m);
    setMessage('');
  };

  const checkForEnter = (e) => {
    if (e.key === 'Enter') {
      sendMessage();
    }
  };

  return (
    <Row md={12} className="compose-message no-gutters">
      <Col className="message-input-col">
        <input
          type="text"
          className="message-input"
          value={message}
          placeholder="Message"
          onChange={(e) => setMessage(e.target.value)}
          onKeyDown={(e) => checkForEnter(e)}
        />
      </Col>
      <Col className="message-submit-col">
        <button className="app-btn" onClick={() => sendMessage()}>
          <FontAwesomeIcon className="airplane-icon" icon={faPaperPlane} />
        </button>
      </Col>
    </Row>
  );
}

const mapStateToProps = (state) => {
  return {
    currentUser: state.currentUser,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    receiveActiveUsers: (users) => dispatch({
      type: 'receive_active_users',
      users: users
    }) 
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MessageInput);
