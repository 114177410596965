import React, { useState, createContext } from "react";
import socketIO from "socket.io-client";

export const SocketContext = createContext();

function SocketProvider({ children }) {
  const [socket, setSocket] = useState(false);

  async function initiateConnection(socketParams) {
    let sockURL = 'https://api.vzero.chat';
    if (process.env.NODE_ENV !== 'production') {
      sockURL = 'http://localhost:5000';
    }
    console.log("Initiating connection:", sockURL);

    await socketIO.connect(sockURL, { query: socketParams });
  }

  return (
    <SocketContext.Provider value={{ socket, initiateConnection }}>
      {children}
    </SocketContext.Provider>
  );
}


export default SocketProvider;