import React, { Component, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import socketIO from 'socket.io-client';

import { getEvents, setActiveChat, getLastMessages, getMessages } from './actions.js';

import ContactsPane from './ContactsPane.js';
import MessagePane from './MessagePane.js';
import MessageInput from './MessageInput.js';
import MessageHeader from './MessageHeader.js';

import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

function Chat(props) {

  const navigate = useNavigate();
  const [message, setMessage] = useState('');
  const [formError, setFormError] = useState('');
  const [socket, setSocket] = useState(false);
  
  const handleSubmit = (e) => {
    e.preventDefault();
  };

  useEffect(() => {
    let s;
    const token = document.cookie.split('=')[1];
    if (!token) {
      navigate('/signin');
    }else {
      console.log("SOCKET::Connecting...");

      let sockURL = 'https://api.vzero.chat';
      if (process.env.NODE_ENV !== 'production') {
        sockURL = 'http://localhost:5000';
      }

      s = socketIO.connect(sockURL, { query: { token: token }});
      setSocket(s);

      if (s) {
          s.on('event', e => {
            e = JSON.parse(e);
            console.log("Got Event", e['id']);
            props.receiveEvent(e);
          });
      }


      props.getEvents().then(events => {
        if (events?.data && events.data.length > 0) {
          const chat = events.data[0];
          props.setActiveChat(chat);
          props.getMessages('initial_load', chat.chat_id, 50);
        }
      });

      props.getLastMessages();
    }

    return () => {
      if (s) {
        s.disconnect();
        console.log("SOCKET::Disconnected");
      }
    }
  }, []);


  return (
    <Container className="h100" fluid="true">
      <Row className="h100 no-gutters">
        <Col className="contacts-container h100" fluid="true">
          <ContactsPane socket={ socket } />
        </Col>
        <Col className="message-container h100" fluid="true">
          <MessageHeader {...props} />
          <MessagePane activeChat={ props.activeChat } socket={ socket } />
          <MessageInput socket={ socket } activeChat={ props.activeChat } />
        </Col>
      </Row>
    </Container>
  );
}

const mapStateToProps = (state) => {
  return {
    activeChat: state.activeChat,
    currentUser: state.currentUser
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getEvents: () => dispatch(getEvents()),
    receiveEvent: (data) => dispatch({
      type: 'receive_event',
      data: data
    }),
    receiveActiveUsers: (users) => dispatch({
      type: 'receive_active_users',
      users: users
    }),
    setActiveChat: (chat) => dispatch(setActiveChat(chat)),
    getLastMessages: () => dispatch(getLastMessages()),
    getMessages: (call_type, chat_id, count) => 
    dispatch(getMessages(call_type, chat_id, count))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Chat);
