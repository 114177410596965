export const activeUsersReducer = (state=[], action) => {
  switch (action.type) {
    case 'receive_active_users':
      if (action.users) {
        return action.users;
      }
      return state;
    default:
      return state;
  }
};

export const activeChatReducer = (state={}, action) => {
  switch (action.type) {
    case 'set_active_chat':
      if (action.chat) {
        return action.chat;
      }
      return state;
    default:
      return state;
  }
};


export const eventsReducer = (state=[], action) => {
  switch (action.type) {
    case 'receive_events':
      if (action.data) {
        return action.data;
      }
      return state;

    case 'receive_event':
      if (action.data) {
        const e = action.data;
        if (e.event_type === 'chat_request') {
          const newState = [...state];
          const idx = newState.findIndex(o => {
            return o.event_type === 'chat_request' && o.id === e.id;
          });

          if (idx !== -1) {
            newState.splice(idx, 1);
          }

          newState.unshift(e);
          return newState;

          // This is when a new chat request is accepted and transforms into a chat
        } else if (e.event_type === 'chat') {
          const newState = [...state];
          const idx = newState.findIndex(o => {
            return o.chat_id === e.chat_id; //o.event_type === 'chat_request' &&
          });
          if (idx !== -1) {
            newState.splice(idx, 1);
          }

          newState.unshift(e);
          return newState;
        }
      }
      return state;

    // On new message, update last_active date
    case 'update_last_activity':
      if (action.data) {
        const newState = [...state];

        const msg = JSON.parse(action.data);
        console.log(msg);
        const idx = newState.findIndex(o => {
          return o.chat_id === msg.chat_id;
        });

        if (idx !== -1) {
          const chat = newState[idx];
          newState.splice(idx, 1);
          newState.unshift(chat);
        }

        return newState;     
      }
      return state;
    default:
      return state;
  }
}


export const messagesReducer = (state={}, action) => {
   switch (action.type) {

    // Get most recent message from each chat via http
    case 'receive_last_messages':
      if (action.data) {
        let newState = {};
        action.data.forEach(m => {
          if (newState[m.chat_id]) {
            newState[m.chat_id].push(m);
          } else {
            newState[m.chat_id] = [m];
          }
        })
        return newState;
      }
      return state;

    // Receive message via websocket
    case 'receive_message':
      if (action.data) {
        const m = JSON.parse(action.data);
        let newState = { ...state };
        if (newState[m.chat_id]) {
          newState[m.chat_id].push(m);
        } else {
          newState[m.chat_id] = [m];
        }
        return newState;
      }
      return state;

    // Fetching all messages for a chat_id via http
    case 'initial_load':
      if (action.data) {
        let newState = {...state};
        newState[action.chat_id] = [];
        action.data.forEach(m => {
          if (newState[m.chat_id]) {
            newState[m.chat_id].push(m);
          } else {
            newState[m.chat_id] = [m];
          }
        })
        return newState;
      }
      return state;

    default:
      return state;
  } 
}