import React, { Component, useState, useEffect } from 'react';
import { connect } from 'react-redux';

import { receiveMessage, updateLastActivity } from './actions';

import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'


function MessagePane(props) {
  useEffect(() => {
    console.log("Message pane mounting")
    if (props.socket) {
      console.log("Binding receive-message")
      props.socket.on('receive-message', msg => {
        console.log("New message", msg)
        props.receiveMessage(msg.data);
        props.updateLastActivity(msg.data);
      });
    }
  }, [props.socket]);


  const buildMessages = (messages) => {
    const l = [];
    messages.forEach((m) => {
      let classes = "message";
      if (m.user_id === props.currentUser.id) {
        classes += " own-message"
      }
      // TODO add message timestamp
      l.push((
        <Row key={m.id} className="no-gutters message-row">
          <Col className={classes}>
            {m.body}

            <span className="message-timestamp"></span>
          </Col>
        </Row>
      ));
    });
    return l;
  }

  if (!props.activeChat || !props.messages) return false;

  return (
    <Row className="message-pane no-gutters">
      <Col>
        {buildMessages(props.messages)}
      </Col>
    </Row>
  );
}

const mapStateToProps = (state, ownProps) => {
  let messages = [];
  if (state.messages.hasOwnProperty(ownProps.activeChat.chat_id)) {
    messages = [].concat(state.messages[ownProps.activeChat.chat_id]);
  }
  return {
    currentUser: state.currentUser,
    messages: messages 
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    receiveMessage: (msg) => dispatch(receiveMessage(msg)),
    updateLastActivity: (msg) => dispatch(updateLastActivity(msg))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MessagePane);
