import React, { Component, useState, useEffect } from 'react';
import { connect } from 'react-redux';

import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import * as iconList from '@fortawesome/free-regular-svg-icons';

import { recipients } from '../../util/helpers.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { sendChatRequest, acceptChatRequest, setActiveChat, getMessages } from './actions';

import { faPaperPlane, faPenToSquare, faCircleLeft, faCircleCheck } from '@fortawesome/free-regular-svg-icons'

function ContactsPane(props) {
  const [showNewConversation, setShowNewConversation] = useState(false);
  const [searchContact, setSearchContact] = useState('');
  
  // console.log(iconList);
  
  useEffect(() => {
    console.log("Running on mount from ActiveUsers", props.socket);
    
    if (props.socket) {
      props.socket.on('receive-chat-request', msg => {
        console.log("receive-chat-request:", msg)
        props.receiveChatRequest(msg.data);
      });
    }
  }, [props.socket]);

  const sendChatRequest = () => {
    props.sendChatRequest({requestedUsername: searchContact});
    setSearchContact('');
    setShowNewConversation(false);
  };

  const acceptRequestClicked = (req) => {
    props.acceptChatRequest({ req: req })
    .then(chat => {
      console.log("Acccepting chat response:", chat);
      props.setActiveChat(chat);
    });
  };

  const chatClicked = (chat) => {
    props.setActiveChat(chat);

    // Check here for if the chat has loaded initial messages;
    props.getMessages('initial_load', chat.chat_id, 50);
  }

  const buildEventsList = (events) => {
    const l = [];
    events.forEach(e => {
      let classes = '';
      if (props.activeChat.chat_id === e.chat_id) {
        classes = 'active';
      }
      if (e.event_type === 'chat_request') {
        if (e.requestor_id === props.currentUser.id) {
          l.push((
            <Row key={`request-${e.id}`} className={`event-tile no-gutters ${classes}`}>
              <Col className="event-profile-circle-col">
                <div className="event-profile-circle">
                  {e.requestee_username.substring(0,1).toUpperCase()}
                </div>
              </Col>
              <Col className="event-summary-col">
                <div className="username">
                  {e.requestee_username}
                </div>
                <div className="summary-text">
                  <i>Chat invitation sent</i>
                </div>
              </Col>
              <Col className="event-meta-col">
                <div className="timestamp"></div>
              </Col>
            </Row>
          ));
        } else if (e.requestee_id === props.currentUser.id) {
          l.push((
            <Row key={`request-${e.id}`} className={`event-tile no-gutters ${classes}`}>
              <Col className="event-profile-circle-col">
                <div className="event-profile-circle">
                  {e.requestor_username.substring(0,1).toUpperCase()}
                </div>
              </Col>
              <Col className="event-summary-col">
                <div className="username">
                  {e.requestor_username}
                </div>
                <div className="summary-text">
                  Accept chat invite?
                  <button className="app-btn" onClick={() => acceptRequestClicked(e)}>
                    <FontAwesomeIcon className="accept-icon" icon={faCircleCheck} />
                  </button>
                </div>
              </Col>
              <Col className="event-meta-col">
                <div className="timestamp"></div>
              </Col>
            </Row>
          ));      
        }
      } else if (e.event_type === 'chat') {

        const recipient = recipients(e, props.currentUser);

        let msgSummary = <i>Send the first message!</i>;
        if (props.messages.hasOwnProperty(e.chat_id) &&
          props.messages[e.chat_id].length > 0) {
          msgSummary = props.messages[e.chat_id][props.messages[e.chat_id].length-1].body;
          if(msgSummary.length > 50) {
            msgSummary = msgSummary.substring(0,50) + '...';
          }
        }
        l.push((
          <Row key={e.chat_id} 
            className={`event-tile no-gutters ${classes}`} 
            onClick={() => chatClicked(e)}>
            <Col className="event-profile-circle-col">
              <div className="event-profile-circle">
                {recipient.initials}
              </div>
            </Col>
            <Col className="event-summary-col">
              <div className="username">
                {recipient.username}
              </div>
              <div className="summary-text">
                {msgSummary}
              </div>
            </Col>
            <Col className="event-meta-col">
              <div className="timestamp"></div>
            </Col>
          </Row>
        ));       
      }
      
    });
    return l;
  }

  if (!props.currentUser?.username) {
    return false;
  }

  let activePane = null;
  if (showNewConversation) {
    let sendInviteBtn = false;
    if (searchContact !== '') {
      sendInviteBtn = (
        <button className="app-btn send-invite-btn" onClick={sendChatRequest}>
          Send invitation to <span className="username-highlight">{searchContact}</span>
        </button>
      );
    }
    activePane = (
      <>
        <Row className="new-recipient-container no-gutters">
          <Col className="back-btn-col">
            <button className="app-btn" onClick={() => setShowNewConversation(false)}>
              <FontAwesomeIcon className="compose-icon" icon={faCircleLeft} />
            </button>
          </Col>
          <Col className="new-conversation-text">
            New conversation
          </Col>
        </Row>
        <Row className="search-contact no-gutters">
          <Col className="message-input-col">
            <input
              type="text"
              className="search-contact-input"
              value={searchContact}
              placeholder="Search by username"
              onChange={(e) => setSearchContact(e.target.value)}
            />
          </Col>
        </Row>
        <Row className="search-contact no-gutters">
          <Col className="message-input-col">
            { sendInviteBtn }
          </Col>
        </Row>
      </>
    );
  } else {
    activePane = (
      <>
        <Row className="new-recipient-container no-gutters">
          <Col className="profile-circle-col">
            <div className="profile-circle">{props.currentUser.username.substring(0,1).toUpperCase()}</div>
          </Col>
          <Col></Col>
          <Col className="new-message-col">
            <button className="app-btn compose-btn" onClick={() => setShowNewConversation(true)}>
              <FontAwesomeIcon className="compose-icon" icon={faPenToSquare} />
            </button>
          </Col>
        </Row>
        <Row className="contacts-list no-gutters">
          <Col>
            { buildEventsList(props.events) }
          </Col>
        </Row>
      </>
    );
  }

  return activePane;
}

const mapStateToProps = (state) => {
  return {
    activeUsers: state.activeUsers,
    currentUser: state.currentUser,
    activeChat: state.activeChat,
    events: state.events,
    messages: state.messages
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    acceptChatRequest: (args) => dispatch(acceptChatRequest(args)),
    receiveActiveUsers: (users) => dispatch({
      type: 'receive_active_users',
      users: users
    }),
    receiveChatRequest: (req) => dispatch({
      type: 'receive_chat_request',
      req: req
    }),
    setActiveChat: (chat) => dispatch(setActiveChat(chat)),
    sendChatRequest: (username) => dispatch(sendChatRequest(username)),
    getMessages: (call_type, chat_id, count) => dispatch(getMessages(call_type, chat_id, count))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ContactsPane);
