import React from 'react';
import { Link } from 'react-router-dom';

export function capitalize(s) {
	return s.charAt(0).toUpperCase() + s.slice(1);
}

export function getDurationTextFromSeconds(s) {

  const hrs = Math.floor(s / 60 / 60);
  const mins = Math.floor(s / 60) - (hrs * 60);
  const seconds = s % 60;

  let txt = '';
  if (hrs > 0) {
    txt = hrs.toString() + 'h ';
  }
  if (mins > 0) {
    txt += mins.toString() + 'm ';
  }
  if (seconds > 0) {
    txt += seconds.toString() + 's';
  }

  return txt || '0s';
}

export function buildCrumbs(crumbs) {
  return crumbs.map((crumb, i) => {
    let c;
    if (crumb.path && i !== crumbs.length - 1) {
      c = (
            <span key={`crumb-${i}`} className="previous-crumb">
              <Link to={{ pathname: crumb.path }}>{ crumb.text }</Link>
              <span>&#10153;</span>
            </span>
          );
    } else {
      let text = crumb.text;
      if (crumb.path) {
        text = <a target="_blank" rel="noopener noreferrer" href={crumb.path}>{ crumb.text }</a>
      }
      c = <span key={`crumb-${i}`} className="current-crumb">
            {text}
          </span>;
    }
    return c;
  });
}

export function recipients(chat, currentUser) {
  if (chat.chat_type == 'direct') {
    const mems = chat.members.filter(m => m.user_id !== currentUser.id);
    const recipient = mems.pop();
    if (!recipient) return {};
    return {
      initials: recipient.username.substring(0,1).toUpperCase(),
      username: recipient.username
    }
  }
}
