import React, { Component, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import Requests from '../../util/Requests.js';

import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

import Button from 'react-bootstrap/Button';


function SignIn(props) {

  const navigate = useNavigate();
  const [userName, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const [formError, setFormError] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();

    Requests.fetch({
      url: '/login',
      method: 'POST',
      body: { username: userName, password: password }
    })
    .then(data => {
      if (data?.error) {
        setFormError(data.error)
      } else {
        props.setCurrentUser(data.user);
        navigate('/chat');
      }
    });
  };

  useEffect(() => {
    console.log("Sign in page rendered called")
  }, ['']);

  return (
    <Container fluid="true" className="h100 body-container sign-in-page">
      <Row className="sign-in-wrapper no-gutters h100" fluid="sm">
        <Col>
              {/*<h2>Pick a Username</h2>*/}
              <Row><Col>
              <label htmlFor="username"></label>
              <input

                type="text"
                minLength={3}
                name="username"
                id="username"
                className="sign-in-field username"
                value={userName}
                autoComplete="off"
                placeholder="Username"
                onChange={(e) => setUserName(e.target.value)}
              />
              </Col>
              </Row>

              <Row><Col>
              <input
                type="text"
                minLength={3}
                name="password"
                id="password"
                className="sign-in-field password"
                value={password}
                autoComplete="off"
                placeholder="Password"
                onChange={(e) => setPassword(e.target.value)}
              />
              </Col>
              </Row>
              <Button onClick={handleSubmit} variant="flat">Sign in</Button>
            <p>{formError}</p>
        </Col>
      </Row>
    </Container>
  );
}


const mapStateToProps = (state) => {
  return {
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setCurrentUser: (user) => dispatch({ 
      type: 'set_current_user', 
      user: user
    })
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
