import { combineReducers, createStore, compose, applyMiddleware } from 'redux';
import { createBrowserHistory } from 'history';
import thunk from 'redux-thunk';
import logger from 'redux-logger';
import { routerReducer, routerMiddleware } from 'react-router-redux';

import { userReducer } from './modules/main/reducers.js';
import { 
    activeUsersReducer, 
    activeChatReducer, 
    eventsReducer,
    messagesReducer 
} from './modules/chat/reducers.js';

const baseReducer = combineReducers({
    routing: routerReducer,
    currentUser: userReducer,
    activeUsers: activeUsersReducer,
    activeChat: activeChatReducer,
    events: eventsReducer,
    messages: messagesReducer
});

const middleware = [thunk, logger];
export const history = createBrowserHistory();
middleware.push(routerMiddleware(history));

export const store = createStore(baseReducer, {},
    compose(
        applyMiddleware(...middleware),
        window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : f => f
    )
);