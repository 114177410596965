class Requests {

  static fetch(params) {
    const headers = new Headers({ 'Content-Type': 'application/json; charset=UTF-8' });
    
    let reqUrl = params.url;
    if (process.env.NODE_ENV === 'production') {
      reqUrl = 'https://api.vzero.chat' + params.url;
    }

    return fetch(reqUrl, {
      credentials: "include",
      headers: headers,
      method: params.method,
      mode: 'cors',
      body: JSON.stringify(params.body)
    })
    .then((response) => {
      if (response.status === 204){
        return response.text();
      }
      return response.json();
    }).catch((error) => {
      console.log("Caught error in requests:", error);
    });
  }

};

export default Requests;
